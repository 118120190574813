import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
const HeaderNavBar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // To check the current path
    const [disableClick,setDisableClick] = useState(false);

    const switchNavPage = (path) => {
        navigate(path);
        setDisableClick(true);
        window.scrollTo(0, 0);
        setTimeout(function (){
            setDisableClick(false);
        },1000)
        if(window.innerWidth < 700){
            document.querySelector('.menu-trigger').click();
        }
    };

    // Combined scroll function
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            if(window.innerWidth < 700){
                document.querySelector('.menu-trigger').click();
            }
        }
    };

    // Function to navigate to homepage and scroll to specific section
    const navigateAndScroll = (path, sectionId) => {
        scrollToSection(sectionId);
    };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const isActiveMenu = (pathArrays) => {
        return pathArrays.includes(location.pathname) ? 'active' : '';
    };

    return (
        <header className={`header-area header-sticky ${disableClick ? 'disabled_click' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">
                            <a onClick={() => switchNavPage('/')} className="logo">
                                <img src="/assets/images/inline-site-logo.png" alt="Hunting zone" />
                            </a>
                            <ul className="nav">
                                <li className="scroll-to-section">
                                    <a
                                        onClick={() => switchNavPage('/')}
                                        className={isActive('/')}>
                                        Home
                                    </a>
                                </li>
                                <li className="has-sub">
                                    <a className={isActiveMenu(['/information-tech','/ai-machine','/health-care','/banking-financial','/engineering-manufacturing','/retail-telecom','/energy-oil-gas'])}>Employers</a>
                                    <ul className="sub-menu">
                                        <li><a onClick={() => switchNavPage('/information-tech')} className={isActive('/information-tech')}>Information Technology</a></li>
                                        <li><a onClick={() => switchNavPage('/ai-machine')} className={isActive('/ai-machine')}>AI & Machine Learning</a></li>
                                        <li><a onClick={() => switchNavPage('/health-care')} className={isActive('/health-care')}>Healthcare</a></li>
                                        <li><a onClick={() => switchNavPage('/banking-financial')} className={isActive('/banking-financial')}>Banking, Financial and Insurance</a></li>
                                        <li><a onClick={() => switchNavPage('/engineering-manufacturing')} className={isActive('/engineering-manufacturing')}>Engineering & Manufacturing</a></li>
                                        <li><a onClick={() => switchNavPage('/retail-telecom')} className={isActive('/retail-telecom')}>Retail & Telecom</a></li>
                                        <li><a onClick={() => switchNavPage('/energy-oil-gas')} className={isActive('/energy-oil-gas')}>Energy/ Oil & Gas</a></li>
                                    </ul>
                                </li>
                                <li className="has-sub">
                                    <a className={isActiveMenu(['/rpo-solution','/permanent-staffing','/it-solutions'])}>Services</a>
                                    <ul className="sub-menu">
                                        <li><a onClick={() => switchNavPage('/rpo-solution')} className={isActive('/rpo-solution')}>RPO Solution</a></li>
                                        <li><a onClick={() => switchNavPage('/permanent-staffing')} className={isActive('/permanent-staffing')}>Permanent Staffing</a></li>
                                        <li><a onClick={() => switchNavPage('/it-solutions')} className={isActive('/it-solutions')}>IT Solution</a></li>
                                    </ul>
                                </li>
                                <li className="scroll-to-section">
                                    <a onClick={() => navigateAndScroll('/', 'contact-section')} className={isActive('/#contact-section')}>Contact Us</a>
                                </li>
                                <li className="scroll-to-section">
                                    <a onClick={() => navigateAndScroll('/', 'work-with-us-section')} className={isActive('/#work-with-us')}>Work with us</a>
                                </li>
                            </ul>
                            <a className='menu-trigger'>
                                <span>Menu</span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderNavBar;
