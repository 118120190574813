import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import AboutUs from './pages/AboutUs';
import './css/App.css';
import AiMachine from "./pages/AiMachine";
import Banking from "./pages/Banking";
import EnergyOilGas from "./pages/EnergyOilGas";
import EngineeringFinancial from "./pages/EngineeringFinancial";
import HealthCare from "./pages/HealthCare";
import InformationTech from "./pages/InformationTech";
import ItSolutions from "./pages/ItSolutions";
import PermanentStaffing from "./pages/PermanentStaffing";
import RetailTelecom from "./pages/RetailTelecom";
import RpoSolution from "./pages/RpoSolution";
import clickSoundFile from "./assets/tap-notification-180637.mp3";
import HeaderNavBar from "./components/HeaderNavBar";
function App() {
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [tapVisible, setTapVisible] = useState(false);
  // Play the click sound
  const playClickSound = () => {
    const clickSound = new Audio(clickSoundFile);
    clickSound.play();
  };

  // Handle the body click
  const handleClick = (e) => {
    const { clientX: x, clientY: y } = e; // Get click position
    setClickPosition({ x, y }); // Set click position for animation
    setTapVisible(true); // Show tap animation
    playClickSound(); // Play the click sound

    // Hide tap animation after a short delay
    setTimeout(() => {
      setTapVisible(false);
    }, 500); // Duration of tap animation
  };

  // Add event listener on body clicks
  useEffect(() => {
    document.body.addEventListener("click", handleClick);
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);
  return (
      <>
        {tapVisible && (
            <div
                className="tap-animation"
                style={{
                  left: `${clickPosition.x}px`,
                  top: `${clickPosition.y}px`,
                }}
            ></div>
        )}
        <Router>
          {/* //// HEADER //// */}
          <HeaderNavBar />
          {/* //// HEADER //// */}
          <Routes>
            <Route path="/" element={<AboutUs />} />
            <Route path="/ai-machine" element={<AiMachine />} />
            <Route path="/banking-financial" element={<Banking />} />
            <Route path="/energy-oil-gas" element={<EnergyOilGas />} />
            <Route path="/engineering-manufacturing" element={<EngineeringFinancial />} />
            <Route path="/health-care" element={<HealthCare />} />
            <Route path="/information-tech" element={<InformationTech />} />
            <Route path="/it-solutions" element={<ItSolutions />} />
            <Route path="/permanent-staffing" element={<PermanentStaffing />} />
            <Route path="/retail-telecom" element={<RetailTelecom />} />
            <Route path="/rpo-solution" element={<RpoSolution />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </>
  );
}

export default App;
