import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import imageBanner from "../assets/images/banner-image.png";
import FooterComponent from "../components/FooterComponent";

const AboutUs = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Function to navigate to homepage and scroll to specific section
    const navigateAndScroll = (path, sectionId) => {
        scrollToSection(sectionId);
    };
    return (
        <>
            <section className="main-banner" id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 align-self-center">
                            <div className="header-text">
                                <h6>Welcome to Hunting Zone Tech Solutions</h6>
                                <h2>Where we create <em>opportunity!</em></h2>
                                <div className="main-button-gradient">
                                    <div className="scroll-to-section"><a onClick={() => navigateAndScroll('/', 'work-with-us-section')}>Work with us!</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="right-image">
                                <img src={imageBanner} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading">
                                <h6>WHAT WE OFFER</h6>
                                <h4>Client’s requirement,our <em>recruiters!</em></h4>
                                <p>
                                    The recruiting process can be very Complex.
                                    Knowing what is best suited for your client and doing it right is what requires skills and expertise.
                                    With Hunting zone Talent Acquisition professionals undergoing rigorous training; you can cost-effectively achieve your client’s recruitment goals.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="owl-service-item owl-carousel">
                                <div className="item">
                                    <div className="service-item">
                                        <div className="icon">
                                            <img src="/assets/images/service-icon-01.png" alt=""/>
                                        </div>
                                        <h4>Sourcing Support</h4>
                                        <p>Find sourcing support that delves into the ocean of accurate candidates.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="service-item">
                                        <div className="icon">
                                            <img src="/assets/images/service-icon-02.png" alt=""/>
                                        </div>
                                        <h4>Full Life Cycle Recruitment Support</h4>
                                        <p>From reviewing job descriptions to managing the interviews, we will take care of tiny things across the process.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="service-item">
                                        <div className="icon">
                                            <img src="/assets/images/service-icon-03.png" alt=""/>
                                        </div>
                                        <h4>APPLY VMS/MSP Recruitment</h4>
                                        <p>An expert solution for one of the most lucrative segments that bring the right candidates within a small TAT.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="service-item">
                                        <div className="icon">
                                            <img src="/assets/images/service-icon-04.png" alt=""/>
                                        </div>
                                        <h4>Social Recruitment</h4>
                                        <p>A social recruitment service builds an array of candidates via powerful social media platforms.</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="service-item">
                                        <div className="icon">
                                            <img src="/assets/images/service-icon-05.png" alt=""/>
                                        </div>
                                        <h4>Administrative Support</h4>
                                        <p>For any administrative segment recruitment of your client, find expert support by ANS.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-courses" id="courses">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <h6>OUR OFFER</h6>
                                <h4>What We Can <em>Offer</em> You</h4>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-01.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>Sourcing Support</h4>
                                        <p>Sourcing the best talent is the most vital component of
                                            the recruitment process and one that recruiters often
                                            underestimate.
                                            <br/>Its importance cannot be undermined, for the whole
                                            purpose of recruitment is vitiated if suitably qualified
                                            candidates do not participate in your recruitment
                                            process.
                                        <br/>
                                            Sourcing ensures that the right talent is identified, increasing the chances of successful recruitment. It's not just about filling vacancies but about finding candidates who fit the job requirements and organizational culture. By leveraging diverse sourcing channels, such as social media, referrals, and industry-specific platforms, recruiters can tap into both active and passive candidates, ensuring a broader and more qualified pool of applicants.

                                         </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-02.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>END TO END Recruitment</h4>
                                        <p>To plan and execute the right strategy in full lifecycle
                                            recruitment,<br/> our team exhibits keen understanding,
                                            astute industry knowledge, and eagerness to deliver
                                            excellent service to your clients.</p>
                                        <p>We build an end-to-end recruitment solution for your
                                            company.
                                            <br/>The most important stages in full lifecycle
                                            recruitment are;
                                            <br/>• Understanding job descriptions and preparing for
                                            the candidate persona
                                            <br/>• Understanding the technology and technological
                                            requirements of the desired job role
                                            <br/>• Identifying and sourcing potential candidates
                                            <br/>• Narrowing down the applicants with exemplary screening methods
                                            <br/>• Shortlisting with telephonic interviews

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-03.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>VMS/MSP Recruitment</h4>
                                        <p>
                                            To fulfil your client’s temporary staffing needs,
                                            VMS/MSP recruitment can provide a list of candidates
                                            with the required flair within a small TAT. It enables
                                            hiring efficiency, transparency, and timely recruitment.

                                            <br/>VMS is a type of contingent workforce management
                                            using tools such as SAP-Fieldglass, Ariba, and Beeline.
                                            To provide such candidates, finding flexibility and
                                            agility is vital. Accomplish your client’s temporary
                                            staffing needs to hire efficient provisional employ

                                            <br/>ees within the VMS/MSP recruitment.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-04.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>Hire Social Recruiters</h4>
                                        <p>We provide administrative support that assists in improving your
                                            business efficiency, and scaling up your work operations with an
                                            extensive range of proficient administrative services.
                                            <br/>• Resume Formatting
                                            <br/>• Database Regeneration
                                            <br/>• Job Posting and Advert Response Management
                                            <br/>• Background/Reference Checking
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-03.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>Administrative Support</h4>
                                        <p>
                                            To fulfil your client’s temporary staffing needs,
                                            VMS/MSP recruitment can provide a list of candidates
                                            with the required flair within a small TAT. It enables
                                            hiring efficiency, transparency, and timely recruitment.

                                            <br/>VMS is a type of contingent workforce management
                                            using tools such as SAP-Fieldglass, Ariba, and Beeline.
                                            To provide such candidates, finding flexibility and
                                            agility is vital. Accomplish your client’s temporary
                                            staffing needs to hire efficient provisional employ

                                            <br/>ees within the VMS/MSP recruitment.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image_container_row">
                                    <div className="left-image">
                                        <img src="/assets/images/courses-03.jpg" alt=""/>
                                    </div>
                                    <div className="right-content">
                                        <h4>Full Life Cycle Recruitment Support</h4>
                                        <p>
                                            Job Analysis & Planning: Define the role and set hiring objectives.
                                            <br/>Sourcing Candidates: Use multiple channels to identify potential candidates.
                                            <br/>Screening & Shortlisting: Review resumes and conduct initial interviews.
                                            <br/>Interview Process: Evaluate candidates through detailed interviews and assessments.
                                            <br/>Offer & Negotiation: Extend offers and negotiate terms with the selected candidate.
                                            <br/>Onboarding: Help new hires integrate smoothly into the company.
                                            This structured approach ensures a streamlined, effective hiring process.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="simple-cta">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="left-image">
                                <img src="/assets/images/cta-left-image.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-5 align-self-center">
                            <h6>Automation Possible</h6>
                            <h4>Unlocking the Power of Automation</h4>
                            <p>Certain processes are integral, but take up time and resources that could be invested
                                elsewhere. Our automation solutions are developed with the motive of streamlining such
                                processes so that they can be automated without much human intervention. Some will speed
                                up the entire process, while others will automate a portion of it.<br/> Explore and
                                understand how our automation solutions can be beneficial to you.</p>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>

        </>
    );
};

export default AboutUs;