import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const RpoSolution = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Rpo Solution</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-8.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Rpo Solution</em></h4>
                                <p>
                                    <strong>HUNTING ZONE RPO solutions</strong> simplify the talent acquisition process
                                    by significantly increasing the number of qualified hires, while simultaneously
                                    lowering hiring costs and reducing fill time.<br/><br/>

                                    We offer top-notch offshore recruitment services with our highly qualified team and
                                    reliable infrastructure. Our commitment to excellence ensures we meet your changing
                                    demands, helping you seize new opportunities, lower operational costs, and focus on
                                    your core business without the hassle of extra staff.<br/><br/>

                                    <strong>RPO</strong> is your ideal hiring method for a faster, adaptable, and
                                    standardized approach. Collaborating with us means quick resolutions to your queries
                                    and escalations.<br/><br/>

                                    Choose <strong>HUNTING ZONE</strong> as your offshore recruitment solution partner
                                    and experience efficient, effective hiring.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default RpoSolution;