import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const HealthCare = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Health Care</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-3.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Health Care</em></h4>
                                <p>
                                    Our expertise goes beyond the ordinary, as we create tailor-made, robust, and
                                    fortified technology and digital solutions for the health industry's most discerning
                                    minds. From visionary physicians to innovative healthcare ISVs, dynamic hospitals,
                                    and thriving provider networks, we have been the trusted companion in their quest
                                    for excellence.<br/><br/>

                                    At Hunting Zone, we hold true to our belief that technology should be accountable,
                                    affordable, and coordinated with the evolving landscape of regulatory reforms and
                                    structural shifts. As the healthcare industry undergoes a revolution, we embrace the
                                    winds of change and focus on the transformative technology trends shaping its
                                    future.<br/><br/>

                                    Embracing the pulse of digitalization, we infuse mobility, harness big data, empower
                                    cloud computing, fortify data security, and unlock the power of predictive analysis
                                    and reporting. As the steadfast technology partner to healthcare providers
                                    worldwide, we keenly grasp the unique challenges they face.<br/><br/>

                                    With our ingenious software solutions, we pave the way for insights-driven
                                    breakthroughs, making healthcare easily accessible in the digital realm. Whether
                                    catering to individual organizations, small and very multiservice entities, or
                                    dynamic franchises, our bespoke solutions cater to specific needs, enhancing
                                    operational efficiency, and empowering care providers to focus on what they do best:
                                    delivering outstanding care.<br/><br/>

                                    Our domain expertise is the best, perfectly harmonizing with our digital acumen.
                                    Together, we march forward, aligning healthcare and social care to create a symphony
                                    of integrated care like never before. The result? Improved clinical outcomes,
                                    financial prosperity, and seamless operational efficiency.<br/><br/>

                                    Our passion for healthcare and clinical excellence reverberates through every
                                    innovation we bring forth. Join us on this extraordinary journey as we revolutionize
                                    the future of healthcare, one ingenious solution at a time.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default HealthCare;