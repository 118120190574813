import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const ItSolutions = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>IT Solution</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-9.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>IT Solution</em></h4>
                                <p>
                                    <strong>HUNTING ZONE IT solution</strong> aligns with the industry's best, catering
                                    to your technological needs with precision and expertise.<br/><br/>

                                    Our IT solutions encompass a wide array of services, from website development to
                                    application development and Digital Marketing. Drawing inspiration from leading
                                    companies in the IT solution domain, we prioritize client-centric approaches,
                                    ensuring seamless integration and innovation tailored to your business requirements.<br/><br/>

                                    Our team has the skills and knowledge similar to top companies in the industry,
                                    delivering IT solutions that enhance efficiency, security, and drive growth.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default ItSolutions;