import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const EngineeringFinancial = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Engineering & Manufacturing</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-5.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Engineering & Manufacturing</em></h4>
                                <p>
                                    Engineering & Manufacturing<br/><br/>
                                    Embark on a transformative journey with Hunting Zone, your gateway to unparalleled
                                    success in both Engineering and Manufacturing. In the dynamic field of Engineering,
                                    we specialize in identifying and securing top-tier talent, ensuring your critical
                                    projects soar to new heights of achievement. Our recruitment process is your
                                    advantage in overcoming workforce challenges, providing skilled professionals who
                                    embody adaptability and versatility.<br/><br/>

                                    In Manufacturing, where innovation reigns supreme, we lead the way into a future
                                    shaped by cutting-edge technologies and visionary consulting services. By
                                    modernizing IT systems and embracing forward-thinking methodologies, we empower
                                    manufacturers to launch groundbreaking products swiftly and efficiently, defying
                                    traditional time-to-market constraints.<br/><br/>

                                    As trailblazers in both industries, we stand shoulder-to-shoulder with our clients,
                                    supporting them in reshaping business models to meet customer demands and maximize
                                    profits. Together, we embark on an extraordinary journey where tradition meets
                                    innovation, creativity knows no bounds, and the possibilities are
                                    limitless.<br/><br/>

                                    Join us in redefining the future of Engineering and Manufacturing.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default EngineeringFinancial;