import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const EnergyOilGas = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Energy/ Oil & Gas</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-7.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Energy/ Oil & Gas</em></h4>
                                <p>
                                    Welcome to a world where innovation meets expertise, and the energy industry embarks
                                    on a transformative journey like never before. At our core, we are pioneers who
                                    grasp the intricacies of the oil, gas, and energy sector, delving deep into our
                                    clients' aspirations to become their ultimate brand guardians. Our mission? To
                                    revolutionize the way talent is discovered, harnessed, and cultivated, all while
                                    slashing time-to-hire cycles.<br/><br/>

                                    <strong>Versatility is our forte,</strong> and we tailor our business model to the
                                    unique needs of each client. Whether it is a complete outsourcing of recruitment or
                                    a seamless integration with internal recruiters, we step in as the catalyst that
                                    ignites progress.<br/><br/>

                                    <strong>Enter the realm of unparalleled domain expertise,</strong> where our
                                    seasoned professionals wield firsthand experience in utility operations. We stand by
                                    our clients, equipping them to conquer the hurdles that stand in the way of
                                    expedited exploration, streamlined production processes, and compliance with
                                    environmental regulations.<br/><br/>

                                    Amidst an ever-changing landscape, we address the challenges of aging IT
                                    infrastructure, inefficiencies in the supply chain, and the relentless surge in
                                    operational costs. Our ingenious solutions, specially crafted for the energy and
                                    utility industry, pave the path for a reimagined business landscape.<br/><br/>

                                    Together, we embark on a journey of modernization, elevating customer satisfaction
                                    to uncharted heights. As we bridge the gap between traditional systems and
                                    cutting-edge technologies, insights pour forth, empowering our clients to make
                                    agile, informed decisions.<br/><br/>

                                    <strong>Resource optimization becomes second nature,</strong> as we unlock the true
                                    potential of talents and assets. A symphony of efficiency emerges, harmonizing the
                                    elements of progress into a resounding success.<br/><br/>

                                    Join us in shaping a brighter, more sustainable future, where energy drives
                                    progress, and possibilities are boundless. Let's redefine the boundaries of what's
                                    achievable and create a legacy that resonates through generations. Welcome to the
                                    epitome of energy excellence!
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default EnergyOilGas;