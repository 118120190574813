import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const RetailTelecom = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Retail & Telecom</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-6.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Retail & Telecom</em></h4>
                                <p>
                                    In the realm of retail, our digital commerce expertise expands market reach and tailors solutions for unprecedented growth. Seamlessly integrating technology, we elevate businesses from brick-and-mortar to digital marvels.<br/><br/>

                                    <strong>Insights-Driven Progress:</strong><br/>
                                    Powered by cutting-edge AI and automation, our Center of Excellence (CoE) model provides retailers with invaluable insights. Beyond data comprehension, we harness it to unlock new dimensions of cost-efficiency. Our success journey spans digital store development, retail IT infrastructure, next-gen digital marketing, AI-driven analytics, and Omni channel ecommerce fulfillment.<br/><br/>

                                    <strong>Telecom Revolution:</strong><br/>
                                    In the vast digital expanse, India emerges as a telecom hub, fueling a visionary plan for 100 smart cities. Your telecom ally excels across diverse domains, serving key sectors like service providers, infrastructure providers, network & IT vendors, telecommunication equipment manufacturers, and retail & distribution.<br/><br/>

                                    <strong>Elite Telecom Squad:</strong><br/>
                                    Our skilled telecom recruiters cater to every sector's unique demands, ensuring excellence in seamless communication, infrastructure development, network & IT crafting, cutting-edge device manufacturing, and bridging innovation with end-users through retail & distribution.<br/><br/>

                                    <strong>Embark on the Journey:</strong><br/>
                                    Together, we create a new era of shopping experiences and limitless connectivity. Join us in creating tomorrow's experiences today, where dreams are realized, and boundaries are shattered. Embrace the power of innovation with us - where possibilities are boundless.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default RetailTelecom;