import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const Banking = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Banking, Financial and Insurance</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-4.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Banking, Financial and Insurance</em></h4>
                                <p>
                                    As a trailblazing force in the realm of Financial Technology Services, we pride
                                    ourselves on creating tailor-made solutions that ignite a spark of innovation in
                                    financial organizations across the globe.<br/><br/>

                                    Through our artful fusion of software services, digital prowess, and IT-enabled
                                    expertise, we weave together a symphony of possibilities for the Financial Services
                                    industry. Embracing the unique essence of each organization, we empower them to
                                    embrace transformative change without the burden of soaring costs.<br/><br/>

                                    Think of us as the architects of digital metamorphosis, orchestrating a grand
                                    transformation for the Financial Services domain. As catalysts and enablers of
                                    growth, we unlock the hidden potentials that lie within, propelling businesses
                                    toward unparalleled heights of success.<br/><br/>

                                    Having traversed a vast landscape of diverse Financial Services firms, we possess an
                                    intimate understanding of their intricacies. From Financial ISVs to Wealth and
                                    Investment Management, from Asset and Portfolio Management to Capital Markets, from
                                    Loans and Mortgages to Private Equity and Venture Capital, we have left a trail of
                                    success stories in our wake.<br/><br/>

                                    The world of finance and financial services is a realm of complexity, where
                                    evolution is a constant. Fear not, for we possess the alchemical touch of
                                    transformative strategies. With a sprinkle of disruptive technology, we reinvigorate
                                    the ecosystem, allowing Financial Services firms to redefine their very core.
                                    Witness as their operating models reach new heights, their competitiveness soars,
                                    and unexplored avenues of growth beckon.<br/><br/>

                                    Join us, where the future of finance unfurls its vibrant wings and takes flight.
                                    Embrace the fusion of imagination and technology, as together, we create a symphony
                                    of success in the ever-evolving world of finance.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default Banking;