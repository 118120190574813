import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const AiMachine = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>AI & Machine Learning</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-2.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>AI & Machine Learning</em></h4>
                                <p>
                                    "AI is the future," and we are committed to empowering businesses with the talent to
                                    illuminate the future.<br/><br/>

                                    At Hunting Zone, we specialize in Talent Acquisition and recruiting top-tier talent
                                    in the rapidly evolving fields of AI and Machine Learning. These technologies are
                                    revolutionizing industries by driving innovation and efficiency.<br/><br/>

                                    Our expert recruiters understand the unique skills and qualifications required in AI
                                    and Machine Learning roles. We connect leading companies with professionals who
                                    excel in developing intelligent systems, predictive models, and advanced algorithms.<br/><br/>

                                    Whether you need data scientists, machine learning engineers, or AI specialists, we
                                    ensure you have the right talent to stay ahead in this competitive
                                    landscape.<br/><br/>

                                    Collaborate with us to harness the power of AI and Machine Learning for
                                    transformative business success.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default AiMachine;