import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const PermanentStaffing = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Permanent Staffing</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image-10.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Permanent Staffing</em></h4>
                                <p>
                                    <strong>Finding the Right Permanent Fit</strong><br/><br/>

                                    Our comprehensive industry research ensures we find the perfect permanent match for
                                    your company's culture and requirements.<br/><br/>

                                    <strong>Why Us for Permanent Staffing:</strong><br/><br/>

                                    <strong>Experience:</strong> We have partnered with various industries, including
                                    Fortune 500 companies, federal agencies, and more.<br/><br/>

                                    <strong>Recruitment:</strong> We ease the burden of finding the right permanent
                                    employee, allowing you to focus on your business.<br/><br/>

                                    <strong>Retention:</strong> Our services extend beyond recruitment to ensure ongoing
                                    support for employee and employer satisfaction.<br/><br/>

                                    <strong>Strategic Staffing:</strong> We align your business goals with the right
                                    personnel for optimal coordination.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default PermanentStaffing;