import React, {useState} from 'react';

const FooterComponent = () => {
    const [success,setSuccess] = useState(false);
    const [successContact,setSuccessContact] = useState(false);



    const handleContactSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const form = event.target; // Get the form element
        const formData = new FormData(form); // Gather the form data

        try {
            const response = await fetch("https://huntingzonetech.com/submit_contact_form.php", {
                method: "POST",
                body: formData, // Send the form data
            });

            const result = await response.text(); // Get the response from the backend

            setSuccessContact(true)
            form.reset()
            setTimeout(()=>{
                setSuccessContact(false)
            },5000)
        } catch (error) {
            console.error("Error submitting form:", error); // Handle errors
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const form = event.target; // Get the form element
        try {
            const response = await fetch("https://huntingzonetech.com/submit_form.php", {
                method: "POST",
                body: formData,
            });

            const result = await response.text();

            setSuccess(true)
            form.reset()
            setTimeout(()=>{
                setSuccess(false)
            },5000)

            console.log(result); // Handle the result or show a message to the user
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <>
            <section className="contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6" id="contact-section">
                            <form id="contact" className="form_content" method="post" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-heading for_form">
                                            <h4>Contact <em>Us</em></h4>
                                            {successContact ?
                                                <p style={{color: 'red'}}>Your data successfully submitted we will get
                                                    back to you soon!</p> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <input type="text" name="full_name" placeholder="Full Name"
                                                   autoComplete="on" required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*"
                                                   placeholder="Your Email" required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <input type="text" name="title" placeholder="Title" autoComplete="on"
                                                   required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <textarea name="message" id="message" placeholder="Your Message"></textarea>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <button type="submit" id="form-submit" className="main-gradient-button">Send
                                                Message
                                            </button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6" id={"work-with-us-section"}>
                            <form id="work-with-us" name="work-with-us" className="form_content"
                                  onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-heading for_form">
                                            <h4>Work With <em>Us</em></h4>
                                            {success ?
                                                <p style={{color: 'red'}}>Your data successfully submitted we will get
                                                    back to you soon!</p> : ''
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="text" name="full_name" placeholder="Full Name"
                                                   autoComplete="on" required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="text" name="email" id="email" pattern="[^ @]*@[^ @]*"
                                                   placeholder="Your Email" required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="text" name="com" placeholder="Company" autoComplete="on"
                                                   required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <input type="text" name="title" placeholder="Title" autoComplete="on"
                                                   required/>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <select className="custom-select" defaultValue={"immediately"} id="hiring-timeframe"
                                                    name="hiring-timeframe" required>
                                                <option value="immediately" >Looking to hire immediately
                                                </option>
                                                <option value="future">Looking to hire in the future</option>
                                            </select>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-6">
                                        <fieldset>
                                            <select defaultValue={"Information Technology"} className="custom-select" id="talent-type" name="talent-type"
                                                    required>
                                                <option value="Information Technology" >Information Technology
                                                </option>
                                                <option value="AI & Machine Learning">AI & Machine Learning</option>
                                                <option value="Healthcare & Clinical">Healthcare & Clinical</option>
                                                <option value="Banking, Financial and Insurance">Banking, Financial and
                                                    Insurance
                                                </option>
                                                <option value="Engineering & Manufacturing">Engineering &
                                                    Manufacturing
                                                </option>
                                                <option value="Retail & Telecom">Retail & Telecom</option>
                                                <option value="Energy/ Oil & Gas">Energy/ Oil & Gas</option>
                                            </select>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <textarea name="additional_message" cols="30" rows="5"
                                                      placeholder="Additional message" autoComplete="on"
                                                      required></textarea>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-12">
                                        <fieldset>
                                            <button type="submit" id="form-submit"
                                                    className="main-gradient-button">Submit
                                            </button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-12">
                            <ul className="social-icons">
                                <li><a target={"_blank"} href="https://www.instagram.com/hunting_zone_tech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i className="fa fa-instagram"></i></a></li>
                                <li><a target={"_blank"} href="https://x.com/HuntingZonetech?t=BVDxF1ZowbEoQIDDKWmlKw&s=08"><i className="fa fa-twitter"></i></a></li>
                                <li><a target={"_blank"} href="https://www.linkedin.com/company/hunting-zone-technology/"><i className="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-12">
                            <p className="copyright">Copyright © 2024 Hunting Zone, Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default FooterComponent;