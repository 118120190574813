import React from 'react';
import HeaderNavBar from "../components/HeaderNavBar";
import FooterComponent from "../components/FooterComponent";

const InformationTech = () => {
    return (
        <>
            <section className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h1>Information Technology</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-info">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="left-image">
                                <img src="/assets/images/about-left-image.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="section-heading">
                                <h4>About <em>Information Technology</em></h4>
                                <p>
                                    Welcome to the world of Information Technology Excellence! As trailblazers in the
                                    realm of Talent Acquisition, we proudly extend a diverse array of RPO services.
                                    Whether you seek the finest IT staff augmentation companies, the swiftest and most
                                    reliable temporary Talent Acquisition solutions, or the most adept technology
                                    staffing companies, we have got you covered. Our expertise in IT contract
                                    recruitment and technical outsourcing solutions is unparalleled, making us a trusted
                                    name in the industry.<br/><br/>

                                    Are you on the quest for exceptional information technology RPO agencies and firms?
                                    Look no further; you have found the perfect partner for your business. Our journey
                                    has been one of relentless research and hands-on experience, forging a team of
                                    seasoned experts who breathe and live the IT sector. Their profound understanding of
                                    this dynamic field allows them to delve deep into its intricacies, ensuring they
                                    grasp every critical need that arises.<br/><br/>

                                    However, what sets us apart is not just our knowledge; it is our unwavering
                                    commitment to understanding the essence of your organization. We know that each
                                    entity possesses a unique identity, a vision that drives it forward. Therefore, we
                                    take the time to unravel your objectives and aspirations, ensuring that every
                                    solution we offer is tailor-made to fit your specific needs.<br/><br/>

                                    Equipped with this profound understanding and backed by extensive experience, we
                                    navigate the IT landscape with ease. We have become well versed in the industry's
                                    ins and outs, empowering us to tackle challenges head-on and seize every opportunity
                                    that comes our way.<br/><br/>

                                    So, if you are seeking to soar to new heights of success in the IT world, do not
                                    hesitate to get in touch with us today. Let us be the wind beneath your wings as you
                                    propel your organization toward its desired results. Together, we shall embrace the
                                    future of Information Technology with unwavering excellence.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent/>
        </>
    );
};

export default InformationTech;